import React from "react";
import MainLayout from "../layouts/MainLayout";
import Typography from "../components/common/Typography";
import {Container} from "react-bootstrap";


function Privacy(props){
    return <MainLayout>
               <main  className="py-5">
                   <Container>
                       <Typography component="h1">Privacy policy</Typography>
                       <Typography component="p" >
                           <p>PIGEON NOTICE OF PRIVACY PRACTICES / PRIVACY POLICY</p>
                           <p>
                               This privacy policy has been compiled to better serve those who are concerned with how their 'Personally identifiable
                               information' (PII) is being used and disclosed online and how you can get access to this information. PII, as used in US
                               privacy law and information security, is information that can be used on its own or with other information to identify,
                               contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a
                               clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance
                               with our website. <strong>Please review it carefully.</strong>
                           </p>
                           <h5>What personal information do we collect from the people that participate in the use of our program?</h5>
                           <p>
                               When interacting with our app, you may be requested to enter your name, birthdate, email address, mailing address, phone
                               number, Personal Health Information or other details.
                           </p>
                           <p>
                               When it comes to your health information, you have certain rights.** This section explains your rights and some of
                               <br />
                               our responsibilities to help you.
                           </p>
                           <h5>Get an electronic or paper copy of your medical record</h5>
                           <ul>
                               <li>
                                   You can ask to see or get an electronic or paper copy of your medical record and other health information we have about you.
                                   Ask us how to do this.
                               </li>
                               <li>
                                   We will provide a copy or a summary of your health information, usually within 30 days of your request. We may charge a
                                   reasonable, cost-based fee.
                               </li>
                           </ul>
                           <h5>Ask us to correct your medical record</h5>
                           <ul>
                               <li>
                                   You can ask us to correct health information about you that you think is incorrect or incomplete. Ask us how to do this.
                               </li>
                               <li>We may say “no” to your request, but we’ll tell you why in writing within 60 days.</li>
                           </ul>
                           <h5>Request confidential communications</h5>
                           <ul>
                               <li>
                                   You can ask us to contact you in a specific way (for example, home or office phone) or to send mail to a different address.
                               </li>
                               <li>We will say “yes” to all reasonable requests. Ask us to limit what we use or share</li>
                               <li>
                                   You can ask us not to use or share certain health information for treatment, payment, or our operations. We are not required
                                   to agree -to your request, and we may say “no” if it would affect your care.
                               </li>
                               <li>
                                   If you pay for a service or health care item out-of- pocket in full, you can ask us not to share that information for the
                                   purpose of payment or our operations with your health insurer. We will say “yes” unless laws and / or regulations require us
                                   to share that information.
                               </li>
                           </ul>
                           <h5>Get a list of those with whom we’ve shared information</h5>
                           <ul>
                               <li>
                                   You can ask for a list (accounting) of the times we’ve shared your health information for six years prior to the date you
                                   ask, who we shared it with, and why.
                               </li>
                               <li>
                                   We will include all the disclosures except for those about treatment, payment, and health care operations, and certain other
                                   disclosures (such as any you asked us to make). We’ll provide one accounting a year for free but will charge a reasonable,
                                   cost-based fee if you ask for another one within 12 months.
                               </li>
                           </ul>
                           <h5>When do we collect information?</h5>
                           <p>We collect information when you respond to a text sent by the system.</p>
                           <h5>How do we use your information?</h5>
                           <p>We may use the information we collect from you when you in the following ways:</p>
                           <ul>
                               <li>To personalize user's experience</li>
                               <li>To allow us to better service you in responding to your customer service requests</li>
                               <li>Conduct analysis on aggregated data collected from the service</li>
                               <li>Treat you</li>
                               <li>Run our organization</li>
                               <li>Bill for your services</li>
                               <li>Help with public health and safety issues</li>
                               <li>Do research</li>
                               <li>Comply with the law</li>
                               <li>Respond to organ and tissue donation requests</li>
                               <li>Work with a medical examiner or funeral director</li>
                               <li>Address workers’ compensation, law enforcement, and other government requests</li>
                               <li>Respond to lawsuits and legal actions</li>
                           </ul>
                           <h5>How do we protect visitor information?</h5>
                           <p>
                               Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site
                               as safe as possible. Your personal information is encrypted and stored on secured networks and is only accessible by a limited
                               number of persons who have special access rights to such systems, and are required to keep the information confidential. In
                               addition, all sensitive information you supply is encrypted via Secure Socket Layer (SSL) technology. We implement a variety
                               of security measures in the office and on the server to maintain the safety of your personal information.
                           </p>
                           <h5>Third-party disclosure</h5>
                           <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable or health information.</p>
                           <h5>Third-party links</h5>
                           <p>We do not include or offer third-party products or services.</p>
                           <h5>California Online Privacy Protection Act</h5>
                           <p>Users will be notified of any privacy policy changes:</p>
                           <ul>
                               <li>
                                   On our Privacy Policy Page
                                   <br />
                                   Users are able to change their personal information:
                               </li>
                               <li>By calling us</li>
                               <li>By using the system to text us</li>
                           </ul>
                           <h5>COPPA (Children Online Privacy Protection Act)</h5>
                           <p>We do not specifically market to children under 13.</p>
                           <h5>Fair Information Practices</h5>
                           <p>
                               In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach
                               occur:
                               <br />
                               We will notify the users via email
                           </p>
                           <ul>
                               <li>
                                   Within 7 business days of discovery of the breach
                                   <br />
                                   We will notify the users via in-site notification
                               </li>
                               <li>Within 7 business days of discovery of the breach</li>
                           </ul>
                           <h5>CAN SPAM Act</h5>
                           <p>
                               The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives
                               recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.
                           </p>
                           <p>We may collect your email address in order to:</p>
                           <ul>
                               <li>Send information, respond to inquiries, and/or other requests or questions.</li>
                               <li>Process orders and to send information and updates pertaining to orders.</li>
                           </ul>
                           <p>
                               To be in accordance with CANSPAM we agree to the following:
                               <br />
                               -NOT use false or misleading subjects or email addresses.
                           </p>
                           <ul>
                               <li>Identify the message as an advertisement in some reasonable way.</li>
                               <li>Include the physical address of our business or site headquarters.</li>
                               <li>Monitor third-party email marketing services for compliance, if one is used.</li>
                               <li>Honor opt-out/unsubscribe requests quickly.</li>
                               <li>Allow users to unsubscribe by using the link at the bottom of each email.</li>
                           </ul>
                           <h5>Contacting Us</h5>
                           <p>If there are any questions regarding this privacy policy you may contact us using the information below.</p>
                           <p>admin@pigeonhealth.com</p>
                           <p>
                               <em>Last Edited on 7/7/2016.</em>
                           </p>
                       </Typography>
                   </Container>
               </main>
    </MainLayout>
}

export default Privacy;